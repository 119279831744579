<template><div></div></template>

<script>
export default {
  props: {
    Categories: Array,
  },
  watch: {
    Categories: {
      handler: function () {
        if (this.Categories.length > 0)
          this.$emit("SelectCategoryByURL", this.$route.params.id);
      },
      deep: true,
    },
  },
};
</script>

<style></style>
