<template>
  <div class="modal_view">
    <div class="modal_view_background"></div>
    <transition name="modal_view_sub_animation">
      <div class="modal_view_subwindow" v-if="Loaded == true">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.297 3.25C12.7197 2.25 11.2763 2.25 10.6989 3.25L1.75008 18.75C1.17273 19.75 1.89441 21 3.04912 21H20.9469C22.1016 21 22.8233 19.75 22.246 18.75L13.297 3.25ZM12.2708 9.63181C12.5523 9.63181 12.7784 9.86421 12.7706 10.1457L12.6737 13.6402C12.6662 13.9108 12.4446 14.1263 12.1738 14.1263H11.8186C11.5478 14.1263 11.3263 13.9108 11.3187 13.6402L11.2218 10.1457C11.214 9.86421 11.4401 9.63181 11.7216 9.63181H12.2708ZM11.9986 17.5411C11.7333 17.5411 11.5064 17.4484 11.3178 17.263C11.1293 17.0745 11.0366 16.8475 11.0398 16.5823C11.0366 16.3202 11.1293 16.0965 11.3178 15.9111C11.5064 15.7225 11.7333 15.6283 11.9986 15.6283C12.2575 15.6283 12.4812 15.7225 12.6698 15.9111C12.8583 16.0965 12.9542 16.3202 12.9574 16.5823C12.9542 16.7581 12.9079 16.9195 12.8184 17.0665C12.7321 17.2103 12.617 17.3254 12.4732 17.4116C12.3294 17.4979 12.1712 17.5411 11.9986 17.5411Z"
            fill="#D62C2C"
          />
        </svg>
        <span class="text_xl warning_title">{{ Title }}</span>
        <span class="text_xs warning_description">{{ Description }}</span>
        <div class="warning_buttons">
          <div
            class="text_xs button button_secondary"
            style="width: calc(50% - 36px)"
            @click="NoSave"
          >
            {{ Button2Title }}
          </div>
          <div
            class="text_xs button button_primary"
            style="width: calc(50% - 36px)"
            @click="Save"
          >
            {{ Button1Title }}
          </div>
        </div>
        <div
          class="button image_button button_noborder modal_view_subwindow_exit_button"
          @click="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.5303 8.53033C16.8232 8.23744 16.8232 7.76256 16.5303 7.46967C16.2374 7.17678 15.7626 7.17678 15.4697 7.46967L12 10.9393L8.53033 7.46967C8.23744 7.17678 7.76256 7.17678 7.46967 7.46967C7.17678 7.76256 7.17678 8.23744 7.46967 8.53033L10.9393 12L7.46967 15.4697C7.17678 15.7626 7.17678 16.2374 7.46967 16.5303C7.76256 16.8232 8.23744 16.8232 8.53033 16.5303L12 13.0607L15.4697 16.5303C15.7626 16.8232 16.2374 16.8232 16.5303 16.5303C16.8232 16.2374 16.8232 15.7626 16.5303 15.4697L13.0607 12L16.5303 8.53033Z"
              fill="#121217"
            />
          </svg>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    Title: String,
    Description: String,
    Button1Title: {
      type: String,
      required: false,
      default: "Зберегти",
    },
    Button2Title: {
      type: String,
      required: false,
      default: "Не зберігати",
    },
  },
  data() {
    return {
      Loaded: false,
    };
  },
  mounted() {
    this.Loaded = true;
  },
  methods: {
    Close: function () {
      this.$emit("Close");
    },
    NoSave: function () {
      this.$emit("NoSave");
    },
    Save: function () {
      this.$emit("Save");
    },
  },
};
</script>

<style scoped>
.modal_view_subwindow {
  width: 372px;
}

.warning_title {
  color: var(--base-colors-950, #121217);
  text-align: center;
}

.warning_description {
  color: var(--base-colors-400, #898ca7);
  text-align: center;

  white-space: break-spaces;

  margin-bottom: 24px;
}

.warning_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;

  gap: 8px;
}

.warning_button {
  color: var(--base-colors-00, #fff);
  background: var(--red-600, #d62c2c);
  border: 1px solid var(--red-600, #d62c2c);
}

.warning_button:hover {
  background: var(--red-500, #d62c2cb9);
  border: 1px solid var(--red-500, #d62c2c);
}
</style>
