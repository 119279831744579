<template>
  <div class="table_header">
    <div
      class="table_header_value table_header_sort"
      @click="OpenSort"
      v-click-outside="HideSort"
    >
      {{ Title }}
      <div class="header_sort_icons">
        <div
          class="header_up_icon"
          :class="{
            header_active_sort: SortKey == 1 || SortKey == 3 || SortKey == 5,
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="4"
            viewBox="0 0 5 4"
            fill="none"
          >
            <path d="M2.16506 0L4.33013 3.75H0L2.16506 0Z" fill="#D6D6E1" />
          </svg>
        </div>
        <div
          class="header_down_icon"
          :class="{ header_active_sort: SortKey == 2 || SortKey == 4 }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="4"
            viewBox="0 0 5 4"
            fill="none"
          >
            <path d="M2.5 4L4.66506 0.25H0.334936L2.5 4Z" fill="#DEDEDE" />
          </svg>
        </div>
      </div>
    </div>
    <div
      class="sort_context_menu"
      :class="{ big_sort_context_menu: BigModal == true }"
      :style="{
        right: position == 'right' ? '-0px' : 'null',
      }"
      v-if="OpenSortCheck"
    >
      <span
        class="context_menu_item"
        :class="{
          context_menu_item_active: SortKey == 1,
          context_menu_item_disable: DisableSort1,
        }"
        @click="Sort(1, DisableSort1, SortTitle1)"
        >{{ SortTitle1 }}</span
      >
      <div class="divider_line" v-if="Divider == 1"></div>
      <span
        class="context_menu_item"
        :class="{
          context_menu_item_active: SortKey == 2,
          context_menu_item_disable: DisableSort2,
        }"
        @click="Sort(2, DisableSort2, SortTitle2)"
        >{{ SortTitle2 }}</span
      >
      <div class="divider_line" v-if="Divider == 2"></div>
      <span
        v-if="SortTitle3 && SortTitle3.trim().length > 0"
        class="context_menu_item"
        :class="{
          context_menu_item_active: SortKey == 3,
          context_menu_item_disable: DisableSort3,
        }"
        @click="Sort(3, DisableSort3, SortTitle3)"
        >{{ SortTitle3 }}</span
      >
      <div class="divider_line" v-if="Divider == 3"></div>
      <span
        v-if="SortTitle4 && SortTitle4.trim().length > 0"
        class="context_menu_item"
        :class="{
          context_menu_item_active: SortKey == 4,
          context_menu_item_disable: DisableSort4,
        }"
        @click="Sort(4, DisableSort4, SortTitle4)"
        >{{ SortTitle4 }}</span
      >
      <div class="divider_line" v-if="Divider == 4"></div>
      <span
        v-if="SortTitle5 && SortTitle5.trim().length > 0"
        class="context_menu_item"
        :class="{
          context_menu_item_active: SortKey == 5,
          context_menu_item_disable: DisableSort5,
        }"
        @click="Sort(5, DisableSort5, SortTitle5)"
        >{{ SortTitle5 }}</span
      >
    </div>
  </div>
</template>

<script>
var getSlug = require("speakingurl");

export default {
  props: {
    Title: String,
    SortTitle1: String,
    SortTitle2: String,
    SortTitle3: String,
    SortTitle4: String,
    SortTitle5: String,
    DisableSort1: Boolean,
    DisableSort2: Boolean,
    DisableSort3: Boolean,
    DisableSort4: Boolean,
    Divider: Number,
    SortKey: Number,
    BigModal: Boolean,
    position: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      OpenSortCheck: false,
    };
  },
  mounted() {
    let title = getSlug(this.Title);

    let currentUrl = new URL(window.location);
    let params = currentUrl.searchParams;

    // Проверка наличия параметров create, edit, view и их порядок
    const hasSpecialParams = ["create", "edit", "view"].some((param) =>
      params.has(param)
    );
    let specialParamBefore = false;
    for (let param of params.keys()) {
      if (["create", "edit", "view"].includes(param)) {
        specialParamBefore = true;
        break;
      }
      if (param == "sort-" + title) {
        break;
      }
    }
    let sortPrefix =
      hasSpecialParams && specialParamBefore ? "msort-" : "sort-";

    title = sortPrefix + title;

    var selectedKey = 0;

    if (this.SortTitle1 && getSlug(this.SortTitle1) == params.get(title)) {
      selectedKey = 1;
    } else if (
      this.SortTitle2 &&
      getSlug(this.SortTitle2) == params.get(title)
    ) {
      selectedKey = 2;
    } else if (
      this.SortTitle3 &&
      getSlug(this.SortTitle3) == params.get(title)
    ) {
      selectedKey = 3;
    } else if (
      this.SortTitle4 &&
      getSlug(this.SortTitle4) == params.get(title)
    ) {
      selectedKey = 4;
    } else if (
      this.SortTitle4 &&
      getSlug(this.SortTitle5) == params.get(title)
    ) {
      selectedKey = 5;
    }

    if (params.has(title)) {
      this.$emit("sort", selectedKey);
    }
  },
  methods: {
    OpenSort: function () {
      this.OpenSortCheck = !this.OpenSortCheck;
    },
    HideSort: function () {
      this.OpenSortCheck = false;
    },
    Sort: function (Key, DisableSort, KeyTitle) {
      if (DisableSort == true) return;

      if (this.SortKey == Key) {
        this.RemoveURL();

        this.$emit("sort", 0);
      } else {
        this.RemoveURL();

        var title = getSlug(this.Title);

        let currentUrl = new URL(window.location);
        let params = currentUrl.searchParams;

        // Проверка наличия параметров create, edit, view и их порядок
        const hasSpecialParams = ["create", "edit", "view"].some((param) =>
          params.has(param)
        );
        let specialParamBefore = false;
        for (let param of params.keys()) {
          if (["create", "edit", "view"].includes(param)) {
            specialParamBefore = true;
            break;
          }
          if (param == "sort-" + title) {
            break;
          }
        }
        let sortPrefix =
          hasSpecialParams && specialParamBefore ? "msort-" : "sort-";

        var key = getSlug(KeyTitle);

        title = sortPrefix + title;

        params.set(title, key);

        let newUrl = currentUrl.pathname + "?" + params.toString();

        history.pushState({}, null, newUrl);

        this.$emit("sort", Key);
      }

      this.OpenSortCheck = false;
    },
    RemoveURL: function () {
      let currentUrl = new URL(window.location);
      let params = currentUrl.searchParams;

      // Проверка наличия параметров create, edit, view
      const hasSpecialParams = ["create", "edit", "view"].some((param) =>
        params.has(param)
      );
      let sortPrefix = hasSpecialParams ? "msort-" : "sort-";

      // Удаление параметров с найденным префиксом
      for (let param of params.keys()) {
        if (param.startsWith(sortPrefix)) {
          params.delete(param);
        }
      }

      let newUrl =
        currentUrl.pathname +
        (params.toString() ? "?" + params.toString() : "");

      history.pushState({}, null, newUrl);
    },
  },
};
</script>
