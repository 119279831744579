<template>
  <div class="data_page">
    <div class="page">
      <div class="page_root_line" v-if="SelectCategory != null">
        <span
          class="text_xs"
          style="
            color: var(--color-text-secondary, #6c6c89);
            margin-right: 4px;
            cursor: pointer;
          "
          @click="SelectCategory = null"
          >Процеси /
        </span>
        <span class="text_xs">{{ SelectCategory.title }}</span>
      </div>
      <div
        class="page_title_line"
        :style="{ 'padding-top': SelectCategory == null ? '24px' : '0px' }"
      >
        <div class="text_3xl page_title" v-if="SelectCategory == null">
          Процеси
        </div>
        <div class="text_3xl page_title" v-else>
          {{ SelectCategory.title }}
        </div>
        <div class="text_xs button button_primary" @click="AddNewProcess">
          Додати учасника
        </div>
        <div
          class="button button_noborder image_button"
          @click.prevent.stop="OpenProcessesContextMenu($event, element)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <mask id="path-1-inside-1_559_19157" fill="white">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
              />
            </mask>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
              fill="#121217"
            />
            <path
              d="M12 10C13.1046 10 14 9.10455 14 8H12V10ZM10 8C10 9.10455 10.8954 10 12 10V8H10ZM12 6C10.8954 6 10 6.89545 10 8H12V6ZM14 8C14 6.89545 13.1046 6 12 6V8H14ZM12 14C13.1046 14 14 13.1046 14 12H12V14ZM10 12C10 13.1046 10.8954 14 12 14V12H10ZM12 10C10.8954 10 10 10.8954 10 12H12V10ZM14 12C14 10.8954 13.1046 10 12 10V12H14ZM12 16V18C13.1046 18 14 17.1046 14 16H12ZM12 16H14C14 14.8954 13.1046 14 12 14V16ZM12 16V14C10.8954 14 10 14.8954 10 16H12ZM12 16H10C10 17.1046 10.8954 18 12 18V16Z"
              fill="#121217"
              mask="url(#path-1-inside-1_559_19157)"
            />
          </svg>
        </div>
      </div>
      <div class="processes_block" v-if="SelectCategory == null">
        <div
          class="process_block"
          v-for="Category of Categories"
          :key="Category.id"
          @click="SelectCategory = Category"
        >
          <div class="text_l process_title_block">
            {{ Category.title }}
            <div
              class="button button_noborder"
              style="padding: 0px; height: 24px"
              @click.prevent.stop="OpenProcessContextMenu($event, Category)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.5 8C12.5 8.27614 12.2761 8.5 12 8.5C11.7239 8.5 11.5 8.27614 11.5 8C11.5 7.72386 11.7239 7.5 12 7.5C12.2761 7.5 12.5 7.72386 12.5 8Z"
                  stroke="#8A8AA3"
                />
                <path
                  d="M12.5 12C12.5 12.2761 12.2761 12.5 12 12.5C11.7239 12.5 11.5 12.2761 11.5 12C11.5 11.7239 11.7239 11.5 12 11.5C12.2761 11.5 12.5 11.7239 12.5 12Z"
                  stroke="#8A8AA3"
                />
                <path
                  d="M12.5 16C12.5 16.2761 12.2761 16.5 12 16.5C11.7239 16.5 11.5 16.2761 11.5 16C11.5 15.7239 11.7239 15.5 12 15.5C12.2761 15.5 12.5 15.7239 12.5 16Z"
                  stroke="#8A8AA3"
                />
              </svg>
            </div>
          </div>
          <div
            class="text_xs process_description_block"
            v-html="Category.description"
          ></div>
          <div class="process_progress_block">
            <div class="text_xs" style="margin-bottom: 8px">
              {{ GetProgressCategory(Category) }}% завершено
            </div>
            <div class="process_progress_value_block">
              <div
                class="process_progress_value"
                :style="{ width: GetProgressCategory(Category) + '%' }"
              ></div>
            </div>
          </div>
          <div class="process_users_block">
            <div class="process_photo_block">
              <img
                class="avatar avatar_m"
                v-for="item in Processes.filter(
                  (x) => x.categoryId == Category.id
                ).slice(0, 9)"
                :key="item.id"
                :src="
                  'https://firebasestorage.googleapis.com/v0/b/ceoclubapi.appspot.com/o/images%2F' +
                  item.userId +
                  '%2Foriginal_62x62?alt=media'
                "
              />
              <span
                class="text_xs more_count"
                v-if="
                  Processes.filter((x) => x.categoryId == Category.id).length >
                  10
                "
                >+{{
                  Processes.filter((x) => x.categoryId == Category.id).length -
                  10
                }}</span
              >
            </div>
            <span
              class="text_xs"
              style="color: var(--color-text-secondary, #6c6c89)"
              >{{
                Processes.filter((x) => x.categoryId == Category.id).length
              }}
              учасників</span
            >
          </div>
        </div>
      </div>
      <div class="user_process_block" v-else>
        <!-- <div class="text_xs tab_block" style="width: 100%">
          <div
            class="tab"
            style="width: auto"
            v-for="Category of Categories"
            :key="Category.id"
            :class="{ tab_active: SelectCategory.id == Category.id }"
            @click="SelectingCategory(Category, $event)"
          >
            {{ Category.title }}
            <div class="tab_counter">
              {{
                Processes_Storage.filter((x) => x.categoryId == Category.id)
                  .length
              }}
            </div>
          </div>
          <div class="slider" ref="tab-slider" style="left: 0%"></div>
        </div> -->
        <div class="table_controls">
          <div class="field table_control_search">
            <div class="field_input" style="width: 236px">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                  stroke="#B3B5C6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <input
                placeholder="Пошук..."
                v-model="SearchText"
                class="text_xs"
              />
              <svg
                v-if="SearchText.length > 0"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="field_input_clear"
                @click="SearchText = ''"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.5303 8.53033C16.8232 8.23744 16.8232 7.76256 16.5303 7.46967C16.2374 7.17678 15.7626 7.17678 15.4697 7.46967L12 10.9393L8.53033 7.46967C8.23744 7.17678 7.76256 7.17678 7.46967 7.46967C7.17678 7.76256 7.17678 8.23744 7.46967 8.53033L10.9393 12L7.46967 15.4697C7.17678 15.7626 7.17678 16.2374 7.46967 16.5303C7.76256 16.8232 8.23744 16.8232 8.53033 16.5303L12 13.0607L15.4697 16.5303C15.7626 16.8232 16.2374 16.8232 16.5303 16.5303C16.8232 16.2374 16.8232 15.7626 16.5303 15.4697L13.0607 12L16.5303 8.53033Z"
                  fill="#121217"
                />
              </svg>
            </div>
          </div>
          <div class="filter_control_items" v-if="AdminsFilters.length > 0">
            <div class="text_xs button button_secondary">
              Очистити
              <svg
                @click="ResetFilters"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.5303 8.53033C16.8232 8.23744 16.8232 7.76256 16.5303 7.46967C16.2374 7.17678 15.7626 7.17678 15.4697 7.46967L12 10.9393L8.53033 7.46967C8.23744 7.17678 7.76256 7.17678 7.46967 7.46967C7.17678 7.76256 7.17678 8.23744 7.46967 8.53033L10.9393 12L7.46967 15.4697C7.17678 15.7626 7.17678 16.2374 7.46967 16.5303C7.76256 16.8232 8.23744 16.8232 8.53033 16.5303L12 13.0607L15.4697 16.5303C15.7626 16.8232 16.2374 16.8232 16.5303 16.5303C16.8232 16.2374 16.8232 15.7626 16.5303 15.4697L13.0607 12L16.5303 8.53033Z"
                  fill="#121217"
                />
              </svg>
            </div>
            <div
              class="text_xs button button_secondary"
              v-for="AdminFilter of AdminsFilters"
              :key="AdminFilter.id"
            >
              <span style="color: var(--base-colors-300, #b3b5c6)"
                >Відповідальний КМ:</span
              >
              <span v-if="AdminFilter.id == 999"> КМ не призначен </span>
              <span v-else>
                {{
                  Admins_Storage.find((x) => x.id == AdminFilter.id).firstName
                }}
                {{
                  Admins_Storage.find((x) => x.id == AdminFilter.id).lastName
                }}.
              </span>
              <svg
                @click="
                  AdminsFilters = AdminsFilters.filter(
                    (x) => x.id != AdminFilter.id
                  )
                "
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.5303 8.53033C16.8232 8.23744 16.8232 7.76256 16.5303 7.46967C16.2374 7.17678 15.7626 7.17678 15.4697 7.46967L12 10.9393L8.53033 7.46967C8.23744 7.17678 7.76256 7.17678 7.46967 7.46967C7.17678 7.76256 7.17678 8.23744 7.46967 8.53033L10.9393 12L7.46967 15.4697C7.17678 15.7626 7.17678 16.2374 7.46967 16.5303C7.76256 16.8232 8.23744 16.8232 8.53033 16.5303L12 13.0607L15.4697 16.5303C15.7626 16.8232 16.2374 16.8232 16.5303 16.5303C16.8232 16.2374 16.8232 15.7626 16.5303 15.4697L13.0607 12L16.5303 8.53033Z"
                  fill="#121217"
                />
              </svg>
            </div>
          </div>
          <div
            class="text_xs button"
            :class="{
              button_secondary: AdminsFilters.length > 0,
              button_noborder: AdminsFilters.length == 0,
            }"
            @click="OpenFilter"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.75 7C4.33579 7 4 7.33579 4 7.75C4 8.16421 4.33579 8.5 4.75 8.5H19.25C19.6642 8.5 20 8.16421 20 7.75C20 7.33579 19.6642 7 19.25 7H4.75ZM6.75 11C6.33579 11 6 11.3358 6 11.75C6 12.1642 6.33579 12.5 6.75 12.5H17.25C17.6642 12.5 18 12.1642 18 11.75C18 11.3358 17.6642 11 17.25 11H6.75ZM8 15.75C8 15.3358 8.33579 15 8.75 15H15.25C15.6642 15 16 15.3358 16 15.75C16 16.1642 15.6642 16.5 15.25 16.5H8.75C8.33579 16.5 8 16.1642 8 15.75Z"
                fill="#121217"
              />
            </svg>
            Фільтр
          </div>
        </div>
        <div class="data_table">
          <div class="text_xs table_header_line table_header_line_big">
            <div
              class="table_header"
              style="
                min-width: 45px;
                max-width: 45px;
                color: var(--base-colors-600, #565873);
              "
            >
              <div class="table_header_value">#</div>
            </div>
            <HeaderSort
              :Title="'Ім’я'"
              :SortTitle1="'Ім’я А-Я'"
              :SortTitle2="'Ім’я Я-А'"
              :SortTitle3="'Прізвище А-Я'"
              :SortTitle4="'Прізвище Я-А'"
              :Divider="2"
              :SortKey="SortByNameKey"
              @sort="SortByName"
              style="min-width: 280px"
            />
            <div class="table_header" style="min-width: 280px">
              <div class="table_header_value">Статуси задач</div>
            </div>
            <div
              class="table_header"
              style="min-width: 270px; max-width: 270px"
            >
              <div class="table_header_value">Прогрес</div>
            </div>
            <div
              class="table_header"
              style="min-width: 160px; max-width: 160px"
            >
              <div class="table_header_value">Тривалість</div>
            </div>
            <HeaderSort
              :Title="'КМ'"
              :SortTitle1="'КМ призначен'"
              :SortTitle2="'КМ не призначен'"
              :SortKey="SortByAdminKey"
              @sort="SortByAdmin"
              style="min-width: 140px; max-width: 140px"
            />
            <div class="table_header" style="min-width: 64px; max-width: 64px">
              <div class="table_header_value"></div>
            </div>
          </div>
          <SlickList
            v-model:list="Processes"
            lockAxis="y"
            item-key="id"
            class="table_rows table_rows_big"
            :useDragHandle="true"
            :transitionDuration="600"
          >
            <SlickItem
              class="table_row"
              v-for="(element, i) in Processes.filter(FilterUser)
                .filter((x) => x.categoryId == SelectCategory.id)
                .filter(FilterByAdmins)"
              :key="element.id"
              :index="i"
              @click="OpenViewProcess(element)"
            >
              <div
                class="table_item"
                style="
                  min-width: 45px;
                  max-width: 45px;
                  color: var(--base-colors-400, #898ca7);
                "
              >
                <div class="text_xs table_item_value">{{ i + 1 }}</div>
              </div>
              <div class="table_item" style="--min-width: 280px">
                <div class="text_xs table_item_value">
                  <img
                    class="avatar avatar_l"
                    :src="
                      'https://firebasestorage.googleapis.com/v0/b/ceoclubapi.appspot.com/o/images%2F' +
                      element.userId +
                      '%2Foriginal_80x80?alt=media'
                    "
                  />
                  <span
                    >{{
                      Users_Storage.find((x) => x.id == element.userId)
                        .firstName
                    }}
                    {{
                      Users_Storage.find((x) => x.id == element.userId).lastName
                    }}</span
                  >
                </div>
              </div>
              <div class="table_item" style="--min-width: 280px">
                <div class="text_xs table_item_value">
                  <div class="badges_row">
                    <div
                      class="badge badge_rounded badge_small badge_red"
                      v-if="
                        element.tasks &&
                        element.tasks.length > 0 &&
                        element.tasks.filter(
                          (x) =>
                            x.active == true &&
                            (x.status == undefined || x.status == 3)
                        ).length > 0
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M13.3333 7.99984C13.3333 10.6692 11.1693 12.8332 8.49996 12.8332C5.83058 12.8332 3.66663 10.6692 3.66663 7.99984C3.66663 5.33046 5.83058 3.1665 8.49996 3.1665C11.1693 3.1665 13.3333 5.33046 13.3333 7.99984Z"
                          stroke="#D50B3E"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                      </svg>
                      {{
                        element.tasks.filter(
                          (x) =>
                            x.active == true &&
                            (x.status == undefined || x.status == 3)
                        ).length
                      }}
                    </div>
                    <div
                      class="badge badge_rounded badge_small badge_yellow"
                      v-if="
                        element.tasks &&
                        element.tasks.length > 0 &&
                        element.tasks.filter(
                          (x) => x.active == true && x.status == 2
                        ).length > 0
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M8.49997 3.1665V4.1665M11.9177 4.58215L11.2105 5.28926M13.3333 7.99982H12.3333M11.9177 11.4175L11.2105 10.7104M8.49997 11.8332V12.8332M5.7894 10.7104L5.08229 11.4175M4.66663 7.99982H3.66663M5.7894 5.28926L5.08229 4.58215"
                          stroke="#C28800"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {{
                        element.tasks.filter(
                          (x) => x.active == true && x.status == 2
                        ).length
                      }}
                    </div>
                    <div
                      class="badge badge_rounded badge_small badge_green"
                      v-if="
                        element.tasks &&
                        element.tasks.length > 0 &&
                        element.tasks.filter(
                          (x) => x.active == true && x.status == 1
                        ).length > 0
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M4.33337 8.57765L6.06001 10.9425C6.60118 11.6838 7.71197 11.6694 8.23374 10.9144L12.6667 4.5"
                          stroke="#1E874C"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {{
                        element.tasks.filter(
                          (x) => x.active == true && x.status == 1
                        ).length
                      }}
                    </div>
                    <div
                      class="badge badge_rounded badge_small badge_gray"
                      v-if="
                        element.tasks &&
                        element.tasks.length > 0 &&
                        element.tasks.filter((x) => x.active == false).length >
                          0
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M4.33337 4.50016C4.33337 4.13197 4.63185 3.8335 5.00004 3.8335H12C12.3682 3.8335 12.6667 4.13197 12.6667 4.50016V11.5002C12.6667 11.8684 12.3682 12.1668 12 12.1668H5.00004C4.63185 12.1668 4.33337 11.8684 4.33337 11.5002V4.50016Z"
                          stroke="#8A8AA3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {{
                        element.tasks.filter((x) => x.active == false).length
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="table_item"
                style="--min-width: 270px; max-width: 270px"
              >
                <div class="text_xs table_item_value">
                  <div
                    class="process_progress_value_block"
                    style="width: 230px; margin-top: 7px"
                  >
                    <div
                      class="process_progress_value"
                      :style="{ width: GetProgressProcess(element) + '%' }"
                    ></div>
                  </div>
                  {{ GetProgressProcess(element) }}%
                </div>
              </div>
              <div
                class="table_item"
                style="--min-width: 160px; max-width: 160px"
              >
                <div class="text_xs table_item_value">
                  <div class="multiline_info" style="width: calc(170px - 16px)">
                    <span>{{ RemainderToEventDate(element.date) }}</span>
                    <span style="color: var(--base-colors-400, #898ca7)">{{
                      new Date(element.date)
                        .toLocaleString("uk-UA", this.optionsDate)[0]
                        .toUpperCase() +
                      new Date(element.date)
                        .toLocaleString("uk-UA", this.optionsDate)
                        .substring(1)
                        .replaceAll(" о ", " ")
                        .replaceAll(",", "")
                        .replaceAll(" р.", "")
                    }}</span>
                  </div>
                </div>
              </div>
              <div
                class="table_item"
                style="--min-width: 140px; max-width: 140px"
              >
                <div
                  class="text_xs table_item_value"
                  v-if="
                    element.communityManagers &&
                    element.communityManagers.length == 1 &&
                    Admins_Storage.find(
                      (x) => x.id == element.communityManagers[0]
                    )
                  "
                >
                  <img
                    v-if="
                      Admins_Storage.find(
                        (x) => x.id == element.communityManagers[0]
                      ).photo &&
                      Admins_Storage.find(
                        (x) => x.id == element.communityManagers[0]
                      ).photo.length > 0 &&
                      Admins_Storage.find(
                        (x) => x.id == element.communityManagers[0]
                      ).photo.indexOf('data:image/svg+xml') >= 0
                    "
                    class="avatar avatar_s"
                    :src="
                      Admins_Storage.find(
                        (x) => x.id == element.communityManagers[0]
                      ).photo
                    "
                    v-tippy="{
                      content:
                        Admins_Storage.find(
                          (x) => x.id == element.communityManagers[0]
                        ).firstName +
                        ' ' +
                        Admins_Storage.find(
                          (x) => x.id == element.communityManagers[0]
                        ).lastName,
                      appendTo: GetBody(),
                      theme: 'ceo',
                    }"
                  />
                  <img
                    v-else-if="
                      Admins_Storage.find(
                        (x) => x.id == element.communityManagers[0]
                      ).photo &&
                      Admins_Storage.find(
                        (x) => x.id == element.communityManagers[0]
                      ).photo.length > 0
                    "
                    class="avatar avatar_s"
                    :src="
                      'https://firebasestorage.googleapis.com/v0/b/ceoclubapi.appspot.com/o/images%2F' +
                      element.communityManagers[0] +
                      '%2Foriginal_48x48?alt=media'
                    "
                    v-tippy="{
                      content:
                        Admins_Storage.find(
                          (x) => x.id == element.communityManagers[0]
                        ).firstName +
                        ' ' +
                        Admins_Storage.find(
                          (x) => x.id == element.communityManagers[0]
                        ).lastName,
                      appendTo: GetBody(),
                      theme: 'ceo',
                    }"
                  />
                  <span style="overflow: visible">
                    {{
                      Admins_Storage.find(
                        (x) => x.id == element.communityManagers[0]
                      ).firstName
                    }}
                    {{
                      Admins_Storage.find(
                        (x) => x.id == element.communityManagers[0]
                      ).lastName[0]
                    }}.
                  </span>
                </div>
                <div
                  class="text_xs table_item_value"
                  v-if="
                    element.communityManagers &&
                    element.communityManagers.length > 1
                  "
                >
                  <div v-for="admin of element.communityManagers" :key="admin">
                    <img
                      v-if="
                        Admins_Storage.find((x) => x.id == admin) &&
                        Admins_Storage.find((x) => x.id == admin).photo &&
                        Admins_Storage.find((x) => x.id == admin).photo.length >
                          0 &&
                        Admins_Storage.find((x) => x.id == admin).photo.indexOf(
                          'data:image/svg+xml'
                        ) >= 0
                      "
                      class="avatar avatar_s"
                      :src="Admins_Storage.find((x) => x.id == admin).photo"
                      v-tippy="{
                        content:
                          Admins_Storage.find((x) => x.id == admin).firstName +
                          ' ' +
                          Admins_Storage.find((x) => x.id == admin).lastName,
                        appendTo: GetBody(),
                        theme: 'ceo',
                      }"
                    />
                    <img
                      v-else-if="
                        Admins_Storage.find((x) => x.id == admin) &&
                        Admins_Storage.find((x) => x.id == admin).photo &&
                        Admins_Storage.find((x) => x.id == admin).photo.length >
                          0
                      "
                      class="avatar avatar_s"
                      :src="
                        'https://firebasestorage.googleapis.com/v0/b/ceoclubapi.appspot.com/o/images%2F' +
                        admin +
                        '%2Foriginal_48x48?alt=media'
                      "
                      v-tippy="{
                        content:
                          Admins_Storage.find((x) => x.id == admin).firstName +
                          ' ' +
                          Admins_Storage.find((x) => x.id == admin).lastName,
                        appendTo: GetBody(),
                        theme: 'ceo',
                      }"
                    />
                  </div>
                </div>
              </div>
              <div
                class="table_item"
                style="--min-width: 64px; max-width: 64px"
              >
                <span class="text_xs table_item_value">
                  <div
                    class="button button_noborder"
                    @click.prevent.stop="OpenUserContextMenu($event, element)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <mask id="path-1-inside-1_559_19157" fill="white">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                        />
                      </mask>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                        fill="#121217"
                      />
                      <path
                        d="M12 10C13.1046 10 14 9.10455 14 8H12V10ZM10 8C10 9.10455 10.8954 10 12 10V8H10ZM12 6C10.8954 6 10 6.89545 10 8H12V6ZM14 8C14 6.89545 13.1046 6 12 6V8H14ZM12 14C13.1046 14 14 13.1046 14 12H12V14ZM10 12C10 13.1046 10.8954 14 12 14V12H10ZM12 10C10.8954 10 10 10.8954 10 12H12V10ZM14 12C14 10.8954 13.1046 10 12 10V12H14ZM12 16V18C13.1046 18 14 17.1046 14 16H12ZM12 16H14C14 14.8954 13.1046 14 12 14V16ZM12 16V14C10.8954 14 10 14.8954 10 16H12ZM12 16H10C10 17.1046 10.8954 18 12 18V16Z"
                        fill="#121217"
                        mask="url(#path-1-inside-1_559_19157)"
                      />
                    </svg>
                  </div>
                </span>
              </div>
            </SlickItem>
          </SlickList>
        </div>
      </div>
      <router-view
        :Categories="Categories"
        @SelectCategoryByURL="SelectCategoryByURL"
      ></router-view>
      <vue-simple-context-menu
        element-id="ProcessesContextMenu"
        :options="ContextMenuProcessesOptions"
        ref="ProcessesContextMenu"
        @option-clicked="ContextMenuProcessesClick"
      />
      <vue-simple-context-menu
        element-id="ProcessContextMenu"
        :options="ContextMenuProcessOptions"
        ref="ProcessContextMenu"
        @option-clicked="ContextMenuProcessClick"
      />
      <vue-simple-context-menu
        element-id="UserContextMenu"
        :options="ContextMenuUserOptions"
        ref="UserContextMenu"
        @option-clicked="ContextMenuUserClick"
      />
      <transition name="fade">
        <SaveWarningView
          v-if="DoneFinishCheck == true"
          @Close="DoneFinishCheck = false"
          @NoSave="DoneFinishCheck = false"
          @Save="FinishDelete"
          :Title="'Бажаєте зевершити процес для учасника?'"
          :Description="'Учасник більше немає задач для виконання'"
          :Button1Title="'Завершити'"
          :Button2Title="'Ні'"
        />
      </transition>
      <transition name="fade">
        <DeleteWarningView
          v-if="FinishCheck == true"
          @Close="FinishCheck = false"
          @Delete="FinishDelete"
          :Title="'Учасник має невиконані задачі'"
          :Description="'Бажаєте зевершити процес для учасника?'"
          :Button1Title="'Завершити'"
          :Button2Title="'Ні'"
        />
      </transition>
      <transition name="fade">
        <DeleteWarningView
          v-if="DeleteCategoryCheck == true"
          @Close="DeleteCategoryCheck = false"
          @Delete="CategoryDelete"
          :Title="'Видалення процесу також призвиде до видалення всіх учасників з нього'"
          :Description="'Цю дію неможливо буде відмінити'"
          :Button1Title="'Видалити'"
          :Button2Title="'Закрити'"
        />
      </transition>
      <transition name="fade">
        <DeleteWarningView
          v-if="DeleteCheck == true"
          @Close="DeleteCheck = false"
          @Delete="DeleteProcess"
          :Title="'Видалити процес'"
          :Description="'Бажаєте видалити процес для учасника?'"
          :Button1Title="'Так'"
          :Button2Title="'Ні'"
        />
      </transition>
      <transition name="fade">
        <ProcessesFilters
          v-if="FilterOpenCheck"
          @CloseWindow="FilterOpenCheck = false"
          @SetAdminFilters="SetAdminFilters"
          :LoadProcesses="Processes"
          :Admins="Admins_Storage"
          :LoadSelectedAdmins="AdminsFilters"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  getFirestore,
  doc,
  collection,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { SlickList, SlickItem } from "vue-slicksort";

import HeaderSort from "@/components/components_v2/HeaderSort.vue";

import DeleteWarningView from "@/components/components_v2/DeleteWarningView.vue";
import SaveWarningView from "@/components/components_v2/SaveWarningView.vue";

import ProcessesFilters from "@/components/modal_views/processes/ProcessesFilters.vue";

var getSlug = require("speakingurl");

export default {
  props: {},
  components: {
    HeaderSort,
    SlickList,
    SlickItem,
    DeleteWarningView,
    SaveWarningView,
    ProcessesFilters,
  },
  data() {
    return {
      Admin_Storage: {},
      Admins_Storage: [],
      Users_Storage: [],
      Processes_Storage: [],
      ProcessCategories_Storage: [],

      Processes: [],
      Categories: [],

      ContextMenuProcessesOptions: [],
      ContextMenuProcessOptions: [
        {
          name: "Редагувати",
          class: "text_xs context_menu_item ContextMenuSettings",
        },
        {
          type: "divider",
          class: "context_menu_line",
        },
        {
          name: "Видалити",
          class:
            "text_xs context_menu_item ContextMenuDeleteButton ContextMenuDelete",
        },
      ],
      ContextMenuUserOptions: [
        {
          name: "Редагувати",
          class: "text_xs context_menu_item ContextMenuSettings",
        },
        {
          type: "divider",
          class: "context_menu_line",
        },
        {
          name: "Завершити процес",
          class:
            "text_xs context_menu_item ContextMenuDeleteButton ContextMenuDelete",
        },
        {
          name: "Видалити процес",
          class:
            "text_xs context_menu_item context_menu_item_attention ContextMenuDeleteButton ContextMenuDelete",
        },
      ],

      optionsDate: {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
      },

      SelectCategory: null,

      SearchText: "",

      SortByNameKey: 0,
      SortByAdminKey: 0,

      FinishCheck: false,
      DoneFinishCheck: false,
      FinishId: "",

      DeleteCheck: false,
      DeleteId: "",

      DeleteCategoryCheck: false,
      DeleteCategoryId: "",

      FilterOpenCheck: false,
      AdminsFilters: [],
    };
  },
  mounted() {
    document.title = "Процеси — CEO Club CRM";

    axios
      .get("https://getcompiaapi.azurewebsites.net/api/CEOClub/GetLowMidUsers")
      .then((response) => {
        this.Users_Storage = response.data;
      })
      .catch((error) => console.log(error));

    const db = getFirestore();

    const getDataAdmins = async () => {
      const docRef = collection(db, "CommunityManager");
      const docSnap = await getDocs(docRef);

      if (!docSnap.empty) {
        docSnap.forEach((value) => {
          this.Admins_Storage.push(value.data());
        });

        this.Admins_Storage.forEach((admin) => {
          if (admin.photo == undefined || admin.photo.length == 0) {
            admin.photo =
              "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Cpath d='M0 20C0 8.95431 8.95431 0 20 0V0C31.0457 0 40 8.95431 40 20V20C40 31.0457 31.0457 40 20 40V40C8.95431 40 0 31.0457 0 20V20Z' fill='%23EBEBEF'/%3E%3Cpath d='M23.25 16C23.25 17.7949 21.7949 19.25 20 19.25C18.205 19.25 16.75 17.7949 16.75 16C16.75 14.2051 18.205 12.75 20 12.75C21.7949 12.75 23.25 14.2051 23.25 16Z' fill='%23D1D1DB'/%3E%3Cpath d='M14.8475 27.25H25.1525C26.2944 27.25 27.174 26.2681 26.6407 25.2584C25.8563 23.7731 24.068 22 20 22C15.932 22 14.1436 23.7731 13.3592 25.2584C12.8259 26.2681 13.7056 27.25 14.8475 27.25Z' fill='%23D1D1DB'/%3E%3Cdeepl-alert xmlns=''/%3E%3Cdeepl-alert xmlns=''/%3E%3Cdeepl-alert xmlns=''/%3E%3C/svg%3E";
          }
        });

        this.Admin_Storage = this.Admins_Storage.find(
          (x) => x.id == localStorage.webAdminId
        );
      }
    };
    const getDataProcess = async () => {
      const docRef = collection(db, "Processes");
      const docSnap = await getDocs(docRef);

      if (!docSnap.empty) {
        docSnap.forEach((value) => {
          this.Processes_Storage.push(value.data());
        });

        this.Processes_Storage = this.Processes_Storage.filter(
          (x) => x.status != 3
        );

        this.Processes = JSON.parse(JSON.stringify(this.Processes_Storage));
      }
    };
    const getDataProcessCategories = async () => {
      const docRef = collection(db, "ProcessCategory");
      const docSnap = await getDocs(docRef);

      if (!docSnap.empty) {
        docSnap.forEach((value) => {
          this.ProcessCategories_Storage.push(value.data());
        });

        this.ProcessCategories_Storage.sort((a, b) => a.order - b.order);
        this.Categories = JSON.parse(
          JSON.stringify(this.ProcessCategories_Storage)
        );
      }
    };

    getDataAdmins();
    getDataProcess();
    getDataProcessCategories();

    var self = this;

    setTimeout(() => {
      window.addEventListener(
        "updated-process-localstorage-changed",
        (process) => {
          var tempProcess = JSON.parse(process.detail.storage);

          var oldProcess = self.Processes_Storage.find(
            (x) => x.id == tempProcess.id
          );

          if (oldProcess) {
            self.updateObject(oldProcess, tempProcess);
            self.Processes_Storage[
              self.Processes_Storage.findIndex((x) => x.id == oldProcess.id)
            ] = oldProcess;
          } else {
            self.Processes_Storage.push(tempProcess);
          }

          self.Processes = JSON.parse(
            JSON.stringify(self.Processes_Storage)
          ).filter((x) => x.status != 3);

          localStorage.removeItem("updated-process");
        }
      );

      window.addEventListener(
        "delete-process-localstorage-changed",
        (process) => {
          var tempProcess = JSON.parse(process.detail.storage);

          self.Processes_Storage = self.Processes_Storage.filter(
            (x) => x.id != tempProcess.id
          );

          self.Processes = JSON.parse(
            JSON.stringify(self.Processes_Storage)
          ).filter((x) => x.status != 3);

          localStorage.removeItem("delete-process");
        }
      );

      window.addEventListener(
        "updated-process-category-localstorage-changed",
        (process) => {
          var tempProcess = JSON.parse(process.detail.storage);

          var oldProcess = self.ProcessCategories_Storage.find(
            (x) => x.id == tempProcess.id
          );

          if (oldProcess) {
            self.updateObject(oldProcess, tempProcess);
            self.ProcessCategories_Storage[
              self.ProcessCategories_Storage.findIndex(
                (x) => x.id == oldProcess.id
              )
            ] = oldProcess;
          } else {
            self.ProcessCategories_Storage.push(tempProcess);
          }

          self.Categories = JSON.parse(
            JSON.stringify(self.ProcessCategories_Storage)
          );

          localStorage.removeItem("updated-process-category");
        }
      );

      window.addEventListener(
        "delete-process-category-localstorage-changed",
        (process) => {
          var tempProcess = JSON.parse(process.detail.storage);

          self.ProcessCategories_Storage =
            self.ProcessCategories_Storage.filter(
              (x) => x.id != tempProcess.id
            );

          self.Categories = JSON.parse(
            JSON.stringify(self.ProcessCategories_Storage)
          );

          localStorage.removeItem("delete-process-category");
        }
      );
    }, 200);
  },

  beforeUnmount() {
    var self = this;

    window.removeEventListener(
      "updated-process-localstorage-changed",
      (process) => {
        var tempProcess = JSON.parse(process.detail.storage);

        var oldProcess = self.Processes_Storage.find(
          (x) => x.id == tempProcess.id
        );

        if (oldProcess) {
          self.updateObject(oldProcess, tempProcess);
          self.Processes_Storage[
            self.Processes_Storage.findIndex((x) => x.id == oldProcess.id)
          ] = oldProcess;
        } else {
          self.Processes_Storage.push(tempProcess);
        }

        self.Processes = JSON.parse(
          JSON.stringify(self.Processes_Storage)
        ).filter((x) => x.status != 3);

        localStorage.removeItem("updated-process");
      }
    );

    window.removeEventListener(
      "delete-process-localstorage-changed",
      (process) => {
        var tempProcess = JSON.parse(process.detail.storage);

        self.Processes_Storage = self.Processes_Storage.filter(
          (x) => x.id != tempProcess.id
        );

        self.Processes = JSON.parse(
          JSON.stringify(self.Processes_Storage)
        ).filter((x) => x.status != 3);

        localStorage.removeItem("delete-process");
      }
    );

    window.removeEventListener(
      "updated-process-category-localstorage-changed",
      (process) => {
        var tempProcess = JSON.parse(process.detail.storage);

        var oldProcess = self.ProcessCategories_Storage.find(
          (x) => x.id == tempProcess.id
        );

        if (oldProcess) {
          self.updateObject(oldProcess, tempProcess);
          self.ProcessCategories_Storage[
            self.ProcessCategories_Storage.findIndex(
              (x) => x.id == oldProcess.id
            )
          ] = oldProcess;
        } else {
          self.ProcessCategories_Storage.push(tempProcess);
        }

        self.Categories = JSON.parse(
          JSON.stringify(self.ProcessCategories_Storage)
        );

        localStorage.removeItem("updated-process-category");
      }
    );

    window.removeEventListener(
      "delete-process-category-localstorage-changed",
      (process) => {
        var tempProcess = JSON.parse(process.detail.storage);

        self.ProcessCategories_Storage = self.ProcessCategories_Storage.filter(
          (x) => x.id != tempProcess.id
        );

        self.Categories = JSON.parse(
          JSON.stringify(self.ProcessCategories_Storage)
        );

        localStorage.removeItem("delete-process-category");
      }
    );
  },
  methods: {
    OpenProcessesContextMenu(event, item) {
      if (this.Admin_Storage.type == 1) {
        this.ContextMenuProcessesOptions = [
          {
            name: "Налаштування розділу",
            class: "text_xs context_menu_item ContextMenuSettings",
          },
        ];
      } else {
        this.ContextMenuProcessesOptions = [];
      }

      if (this.SelectCategory) {
        this.ContextMenuProcessesOptions.push({
          name: "Редагувати процес",
          class: "text_xs context_menu_item ContextMenuSettings",
        });
      } else {
        this.ContextMenuProcessesOptions.push({
          name: "Додати процес",
          class: "text_xs context_menu_item ContextMenuSettings",
        });
      }
      this.$refs.ProcessesContextMenu.showMenu(event, item);

      this.$nextTick(() => {
        document.getElementById("ProcessesContextMenu").style.top =
          event.pageY + "px";
      });
    },
    ContextMenuProcessesClick(event) {
      if (event.option.name == "Налаштування розділу") {
        this.$emit("EditProcessChapter");
      }
      if (event.option.name == "Редагувати процес") {
        localStorage.shortEditProcessCategory = this.SelectCategory.id;
        this.$emit("EditProcessChapter");
      }
      if (event.option.name == "Додати процес") {
        localStorage.shortAddProcessCategory = true;
        this.$emit("EditProcessChapter");
      }
    },
    OpenProcessContextMenu(event, item) {
      this.$refs.ProcessContextMenu.showMenu(event, item);

      this.$nextTick(() => {
        document.getElementById("ProcessContextMenu").style.top =
          event.pageY + "px";
      });
    },
    ContextMenuProcessClick: async function (event) {
      const db = getFirestore();
      const DataEvent_Ref = doc(
        db,
        "ProcessCategory",
        event.item.id.toString()
      );
      const DataEvent = await getDoc(DataEvent_Ref);
      if (DataEvent.exists()) {
        if (event.option.name == "Редагувати") {
          localStorage.shortEditProcessCategory = event.item.id;
          this.$emit("EditProcessChapter");
          // this.$nextTick(() => {
          //   var tab = document.getElementsByClassName("tab");
          //   tab = tab[this.SelectCategory.order - 1];

          //   this.$refs["tab-slider"].style.width = tab.clientWidth + "px";
          //   this.$refs["tab-slider"].style.left = tab.offsetLeft + "px";
          // });
        }
        if (event.option.name == "Видалити") {
          this.DeleteCategoryCheck = true;
          this.DeleteCategoryId = event.item.id;
        }
      } else {
        this.$emit("SendErrorNotification", "Процесс вже завершено");

        localStorage.setItem(
          "delete-process",
          JSON.stringify({ id: event.item.id })
        );

        window.dispatchEvent(
          new CustomEvent("delete-process-localstorage-changed", {
            detail: {
              storage: localStorage.getItem("delete-process"),
            },
          })
        );
      }
    },
    OpenUserContextMenu(event, item) {
      this.$refs.UserContextMenu.showMenu(event, item);
    },
    ContextMenuUserClick: async function (event) {
      const db = getFirestore();
      const DataEvent_Ref = doc(db, "Processes", event.item.id.toString());
      const DataEvent = await getDoc(DataEvent_Ref);
      if (DataEvent.exists()) {
        if (event.option.name == "Редагувати") {
          this.$emit("EditTasksProcess", event.item.id);
        }
        if (event.option.name == "Завершити процес") {
          if (
            event.item.tasks.filter((x) => x.active == true && x.status != 1)
              .length == 0
          ) {
            this.DoneFinishCheck = true;
          } else {
            this.FinishCheck = true;
          }

          this.FinishId = event.item.id;
        }
        if (event.option.name == "Видалити процес") {
          this.DeleteCheck = true;
          this.DeleteId = event.item.id.toString();
        }
      } else {
        this.$emit("SendErrorNotification", "Процесс вже завершено");

        localStorage.setItem(
          "delete-process",
          JSON.stringify({ id: event.item.id })
        );

        window.dispatchEvent(
          new CustomEvent("delete-process-localstorage-changed", {
            detail: {
              storage: localStorage.getItem("delete-process"),
            },
          })
        );
      }
    },
    OpenViewProcess: function (Process) {
      this.$emit("EditProcess", Process.id);
    },
    FinishDelete: function () {
      const db = getFirestore();
      // deleteDoc(doc(db, "Processes", this.FinishId));
      setDoc(
        doc(db, "Processes", this.FinishId),
        { status: 3 },
        { merge: true }
      );

      localStorage.setItem(
        "delete-process",
        JSON.stringify({ id: this.FinishId })
      );

      window.dispatchEvent(
        new CustomEvent("delete-process-localstorage-changed", {
          detail: {
            storage: localStorage.getItem("delete-process"),
          },
        })
      );

      this.FinishCheck = false;

      this.$emit(
        "SendSuccessfullyNotification",
        "Процес для учасника завершено"
      );
    },
    DeleteProcess: function () {
      const db = getFirestore();
      deleteDoc(doc(db, "Processes", this.DeleteId));

      localStorage.setItem(
        "delete-process",
        JSON.stringify({ id: this.DeleteId })
      );

      window.dispatchEvent(
        new CustomEvent("delete-process-localstorage-changed", {
          detail: {
            storage: localStorage.getItem("delete-process"),
          },
        })
      );

      this.DeleteCheck = false;

      this.$emit(
        "SendSuccessfullyNotification",
        "Процес для учасника видалено"
      );
    },
    CategoryDelete: function () {
      const db = getFirestore();

      var processes = JSON.parse(
        JSON.stringify(
          this.Processes_Storage.filter(
            (x) => x.categoryId == this.DeleteCategoryId
          )
        )
      );

      processes.forEach((process) => {
        deleteDoc(doc(db, "Processes", process.id));
      });

      deleteDoc(doc(db, "ProcessCategory", this.DeleteCategoryId));

      localStorage.setItem(
        "delete-process-category",
        JSON.stringify({ id: this.DeleteCategoryId })
      );

      window.dispatchEvent(
        new CustomEvent("delete-process-category-localstorage-changed", {
          detail: {
            storage: localStorage.getItem("delete-process-category"),
          },
        })
      );

      this.DeleteCategoryCheck = false;

      this.$emit("SendSuccessfullyNotification", "Процес видалений");
    },
    GetProgressCategory: function (Category) {
      var totalReadyTasks = 0;
      var totalTasks = 0;

      this.Processes_Storage.filter((x) => x.categoryId == Category.id).forEach(
        (process) => {
          if (process.tasks && process.tasks.length > 0) {
            totalReadyTasks += process.tasks.filter(
              (x) => x.active == true && x.status == 1
            ).length;
            totalTasks += process.tasks.filter((x) => x.active == true).length;
          }
        }
      );

      if (totalReadyTasks > 0 && totalTasks > 0) {
        return Math.round((totalReadyTasks / totalTasks) * 100);
      } else {
        return 0;
      }
    },
    GetProgressProcess: function (Process) {
      if (
        Process.tasks &&
        Process.tasks.length > 0 &&
        Process.tasks.filter((x) => x.active == true && x.status == 1).length >
          0 &&
        Process.tasks.filter((x) => x.active == true).length > 0
      ) {
        return Math.round(
          (Process.tasks.filter((x) => x.active == true && x.status == 1)
            .length /
            Process.tasks.filter((x) => x.active == true).length) *
            100
        );
      } else {
        return 0;
      }
    },
    AddNewProcess: function () {
      if (this.SelectCategory) {
        localStorage.shortAddProcessUserCategoryId = this.SelectCategory.id;
      }

      this.$emit("AddNewProcess");
    },
    SelectingCategory: function (Category) {
      this.SelectCategory = Category;

      // this.$nextTick(() => {
      //   var element = null;

      //   if (event.target.classList[0] == "tab") {
      //     element = event.target;
      //   } else {
      //     for (
      //       let index = 0;
      //       index < event.target.offsetParent.children.length;
      //       index++
      //     ) {
      //       var tempChild = event.target.offsetParent.children[index];

      //       if (tempChild.className.indexOf("tab_active") >= 0) {
      //         element = tempChild;
      //       }
      //     }
      //   }

      //   this.$refs["tab-slider"].style.width = element.clientWidth + "px";
      //   this.$refs["tab-slider"].style.left = element.offsetLeft + "px";
      // });
    },
    SelectCategoryByURL: function (SelectCategoryId) {
      var selectCategory = this.Categories.find(
        (x) => getSlug(x.title) == SelectCategoryId
      );
      if (
        this.SelectCategory == null ||
        this.SelectCategory.id != selectCategory.id
      ) {
        this.SelectCategory = selectCategory;
      }
    },
    FilterUser: function (x) {
      var user = this.Users_Storage.find((y) => y.id == x.userId);

      if (user) {
        var name =
          user.firstName.toLowerCase() + " " + user.lastName.toLowerCase();

        if (name.indexOf(this.SearchText.toLowerCase()) >= 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    SortByName: function (Key) {
      this.SortByNameKey = Key;

      this.SortByAdminKey = 0;
    },
    SortByAdmin: function (Key) {
      this.SortByAdminKey = Key;

      this.SortByNameKey = 0;
    },
    SortingByFirstName: function (a, b) {
      return a.firstName.localeCompare(b.firstName);
    },
    SortingByFirstNameDescending: function (a, b) {
      return b.firstName.localeCompare(a.firstName);
    },
    SortingByLastName: function (a, b) {
      return a.lastName.localeCompare(b.lastName);
    },
    SortingByLastNameDescending: function (a, b) {
      return b.lastName.localeCompare(a.lastName);
    },
    SortingByAdmin: function (a, b) {
      if (
        a.communityManagers &&
        a.communityManagers.length > 0 &&
        (b.communityManagers == undefined || b.communityManagers.length == 0)
      )
        return -1;
      if (
        b.communityManagers &&
        b.communityManagers.length > 0 &&
        (a.communityManagers == undefined || a.communityManagers.length == 0)
      )
        return 1;

      return 0;
    },
    SortingByAdminDescending: function (a, b) {
      if (
        a.communityManagers &&
        a.communityManagers.length > 0 &&
        (b.communityManagers == undefined || b.communityManagers.length == 0)
      )
        return 1;
      if (
        b.communityManagers &&
        b.communityManagers.length > 0 &&
        (a.communityManagers == undefined || a.communityManagers.length == 0)
      )
        return -1;

      return 0;
    },
    SortProcesses: function () {
      if (this.SortByNameKey == 1) {
        this.Processes = this.Processes.sort((a, b) =>
          this.SortingByFirstName(
            this.Users_Storage.find((x) => x.id == a.userId),
            this.Users_Storage.find((x) => x.id == b.userId)
          )
        );
      }
      if (this.SortByNameKey == 2) {
        this.Processes = this.Processes.sort((a, b) =>
          this.SortingByFirstNameDescending(
            this.Users_Storage.find((x) => x.id == a.userId),
            this.Users_Storage.find((x) => x.id == b.userId)
          )
        );
      }
      if (this.SortByNameKey == 3) {
        this.Processes = this.Processes.sort((a, b) =>
          this.SortingByLastName(
            this.Users_Storage.find((x) => x.id == a.userId),
            this.Users_Storage.find((x) => x.id == b.userId)
          )
        );
      }
      if (this.SortByNameKey == 4) {
        this.Processes = this.Processes.sort((a, b) =>
          this.SortingByLastNameDescending(
            this.Users_Storage.find((x) => x.id == a.userId),
            this.Users_Storage.find((x) => x.id == b.userId)
          )
        );
      }

      if (this.SortByAdminKey == 1) {
        this.Processes = this.Processes.sort(this.SortingByAdmin);
      }
      if (this.SortByAdminKey == 2) {
        this.Processes = this.Processes.sort(this.SortingByAdminDescending);
      }
    },
    uniquePhotos: function (Category) {
      return this.Processes.filter((x) => x.categoryId == Category.id)
        .reduce((unique, process) => {
          const photo = this.Admins_Storage.find(
            (x) => x.id == process.communityManagers[0]
          ).photo;
          if (!unique.find((item) => item.photo === photo)) {
            unique.push({ id: process.id, photo: photo });
          }
          return unique;
        }, [])
        .slice(0, 5);
    },
    ResetFilters: function () {
      this.AdminsFilters = [];
    },
    FilterByAdmins: function (event) {
      if (this.AdminsFilters.length > 0) {
        var check = false;

        if (event.communityManagers && event.communityManagers.length > 0) {
          this.AdminsFilters.forEach((admin) => {
            if (
              event.communityManagers.find((x) => x == admin.id) != undefined
            ) {
              check = true;
            }
          });
        } else if (this.AdminsFilters.find((x) => x.id == 999)) {
          check = true;
        }

        return check;
      }
      return true;
    },
    OpenFilter: function () {
      this.FilterOpenCheck = true;
    },
    SetAdminFilters: function (Admins) {
      this.AdminsFilters = Admins;
    },
    CheckHTMLScroll: function () {
      const html = document.querySelector("html");

      if (this.FilterOpenCheck) {
        html.style.overflow = "hidden";
      } else {
        html.style.overflow = null;
      }
    },
    addParamsToLocation(param) {
      var urlPoints = this.$route.path.split("/");
      history.pushState(
        {},
        null,
        urlPoints[0] + "/" + urlPoints[1] + "/" + param
      );
    },
    updateObject: function (original, updated) {
      // Перебираем все ключи в обновлённом объекте
      for (const key in updated) {
        // Проверяем, есть ли ключ в оригинальном объекте и отличается ли значение

        // eslint-disable-next-line
        if (original[key] && original[key] !== updated[key]) {
          // Обновляем значение в оригинальном объекте
          original[key] = updated[key];
        }
      }
    },
    RemainderToEventDate: function (tempDateString) {
      var tempDate = new Date(tempDateString);

      var timeNow = new Date();

      var timeDiff = Math.abs(timeNow.getTime() - tempDate.getTime());

      var diffHours = Math.ceil(timeDiff / 3600000);
      var diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      if (diffHours <= 24) {
        return "Меньше 1 дня";
      } else if (diffHours < 48) {
        return "1 день";
      } else {
        if (diffDays < 5) return diffDays + " дні";
        else return diffDays + " днів";
      }
    },
    GetBody: function () {
      return document.body;
    },
  },
  watch: {
    SortByNameKey: function () {
      this.SortProcesses();
    },
    SortByAdminKey: function () {
      this.SortProcesses();
    },
    SelectCategory: function () {
      if (this.SelectCategory !== undefined) {
        if (this.SelectCategory != null) {
          this.addParamsToLocation(getSlug(this.SelectCategory.title));
        } else {
          this.addParamsToLocation("");
        }
      }
    },
    FilterOpenCheck: function () {
      this.CheckHTMLScroll();
    },
    $route: {
      handler: function () {
        console.log();
        if (
          (this.$route.name == "ProcessesPage" ||
            this.$route.name == "ProcessPage") &&
          (this.$route.params.id == undefined ||
            this.$route.params.id.length == 0)
        ) {
          this.SelectCategory = null;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.page_title_line {
  transition: 0s;
}

.processes_block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: var(--spacing-4, 16px);
}

.process_block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  width: calc(33% - 8px - 48px);
  min-height: 184px;

  padding: var(--spacing-6, 24px);

  border-radius: var(--spacing-2, 8px);
  background: var(--color-background-accent-gray, #f7f7f8);

  cursor: pointer;
}

.process_title_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.process_description_block {
  display: -webkit-box;

  color: var(--color-text-secondary, #6c6c89);

  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  width: 100%;
  min-height: 44px;
  max-height: 44px;

  margin-bottom: 16px;
}

.process_description_block >>> * {
  display: -webkit-box;

  width: 100%;

  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.process_description_block >>> p {
  margin: 0px;
}

.process_progress_block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  margin-bottom: 24px;
}

.process_progress_value_block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;

  height: 8px;

  border-radius: var(--radius-3xl, 360px);
  background: var(--color-background-progress-track-bar, #ebebef);
}

.process_progress_value {
  height: 8px;

  border-radius: var(--radius-3xl, 360px);
  opacity: var(--spacing-px, 1);
  background: var(--color-background-progress-progress-bar-gray, #121217);
}

.process_users_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  flex-wrap: wrap;

  gap: 8px;
}

.process_photo_block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  z-index: 0;
}

.process_photo_block img {
  border-radius: var(--radius-3xl, 360px);
  border: 2px solid var(--color-background-accent-gray, #f7f7f8);

  margin-left: -10px;
}

.process_photo_block img:nth-child(1) {
  margin-left: 0px;

  z-index: 0;
}
.process_photo_block img:nth-child(2) {
  z-index: -1;
}
.process_photo_block img:nth-child(3) {
  z-index: -2;
}
.process_photo_block img:nth-child(4) {
  z-index: -3;
}
.process_photo_block img:nth-child(5) {
  z-index: -4;
}
.process_photo_block img:nth-child(6) {
  z-index: -5;
}
.process_photo_block img:nth-child(7) {
  z-index: -6;
}
.process_photo_block img:nth-child(8) {
  z-index: -7;
}
.process_photo_block img:nth-child(9) {
  z-index: -8;
}
.process_photo_block img:nth-child(10) {
  z-index: -9;
}
.process_photo_block .more_count {
  z-index: -10;

  display: flex;

  width: 32px;
  height: 32px;

  justify-content: center;
  align-items: center;

  border-radius: var(--radius-3xl, 360px);
  border: 2px solid var(--color-background-select-canvas, #fff);
  background: var(--color-background-cursor-black, #121217);

  color: var(--color-text-white, #fff);
  text-align: center;

  margin-left: -10px;
}

.user_process_block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
}

.tab_block {
  border-bottom: 1px solid var(--base-colors-200, #d6d6e1);
}

.tab {
  border-bottom: none;
}

.slider {
  margin-bottom: -1.5px;
}

@media only screen and (max-width: 991px) {
  .process_block {
    width: calc(100% - 8px);
  }
}
</style>
